import { render, staticRenderFns } from "./newRetail.vue?vue&type=template&id=60bf94bc&scoped=true&"
import script from "./newRetail.vue?vue&type=script&lang=js&"
export * from "./newRetail.vue?vue&type=script&lang=js&"
import style0 from "./newRetail.vue?vue&type=style&index=0&id=60bf94bc&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bf94bc",
  null
  
)

export default component.exports