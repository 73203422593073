<template>
  <div class="newRetail">
    <img src="../../assets/product/newRetail/1.png" class="headerimg" />

    <div class="malpractice">
      <div class="title">传统零售业发展3大弊端</div>

      <div class="malpracticelist">
        <div class="malpracticecard">
          <div class="malpracticeheard">
            <img src="../../assets/product/newRetail/2.png" alt="" />
          </div>
          <div class="malpracticeinfo">
            <div class="malpracticetitle">按经验供货</div>
            <div class="malpracticetext">
              生产与消费需求脱节，按经验进行产品组合/生产
              <div>易出现热销品短缺，滞销品积压的现象</div>
            </div>
          </div>
        </div>
        <div class="malpracticeimg">
          <img src="../../assets/product/newRetail/5.png" />
        </div>
        <div class="malpracticecard">
          <div class="malpracticeheard">
            <img src="../../assets/product/newRetail/3.png" alt="" />
          </div>
          <div class="malpracticeinfo">
            <div class="malpracticetitle">渠道分离</div>
            <div class="malpracticetext">
              线上线下渠道场景分离，营销成本高、效率低
              <div>会员、商品、营销、库存、订单……不能统一</div>
            </div>
          </div>
        </div>
        <div class="malpracticeimg">
          <img src="../../assets/product/newRetail/5.png" />
        </div>
        <div class="malpracticecard">
          <div class="malpracticeheard">
            <img src="../../assets/product/newRetail/4.png" alt="" />
          </div>
          <div class="malpracticeinfo">
            <div class="malpracticetitle">客群模糊</div>
            <div class="malpracticetext">
              客户关系管理粗糙，不能精准触达消费者
              <div>无法挖掘用户喜好，实时做出业务创新</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="strength">
      <div class="title">邦伲德智慧零售三大核心力量</div>
      <div class="title2">以数据驱动运营 挖掘用户存量价值</div>

      <div class="strengthlist">
        <div>
          <div class="strengthtable">
            <div class="strengthline">精准营销铸就更精准个性的“人”</div>
            <div class="strengthline strengthbor">
              流程再造赋能更高效流通的“货”
            </div>
            <div class="strengthline">场景革命打造更全时全域的“场”</div>
          </div>
        </div>

        <div class="strengthcardlist">
          <div class="strengthcard strengthtop backone">
            <div class="strengthcardtitle">多域经营</div>
            <div class="strengthcardline"></div>
            <div class="strengthcardinfo">企业自主经营多渠道云店</div>
            <div class="strengthcardinfo">实现线上线下一体化经营</div>
          </div>
          <div class="strengthcard strengthlef backtwo">
            <div class="strengthcardtitle">私域资产增值</div>
            <div class="strengthcardline"></div>
            <div class="strengthcardinfo">构建私域销售闭环</div>
            <div class="strengthcardinfo">助力企业缩短变现路径</div>
          </div>
          <div class="strengthcard backthree strengthtop">
            <div class="strengthcardtitle">公域拓新沉淀</div>
            <div class="strengthcardline"></div>
            <div class="strengthcardinfo">公域多场景引流获客</div>
            <div class="strengthcardinfo">持续沉淀私域留存</div>
          </div>
        </div>
      </div>
    </div>

    <div class="coreCompetence">
      <div class="title">邦伲德智慧零售核心功能</div>
      <div class="title2">
        以移动应用云平台为依托，实现移动社交电商平台用户、财务、数据管理，社交营销卖货，团队管理等一体化系统解决方案。
      </div>
      <div class="title2">
        真正实现用户通、数据通、奖励通、场景通、渠道通的社交电商系统。
      </div>

      <div class="coreCompetenceimg">
        <img src="../../assets/product/newRetail/9.png" alt="" />
      </div>
    </div>

    <div class="operation">
      <div class="title">邦伲德智慧零售四大运营策略</div>
      <div class="title2">加速构建企业全链路数字化私域布局</div>

      <div class="operationlist">
        <div class="operationimg">
          <img src="../../assets/product/newRetail/10.png" alt="" />
        </div>
        <div class="operationcardlist">
          <div class="operationcard operationtop">
            <div class="operationtitle">门店上云</div>
            <div class="operationinfo">
              品牌全渠道商城 实现线上线下一体化经营
            </div>
          </div>
          <div class="operationcard operationlef">
            <div class="operationtitle">智能导购</div>
            <div class="operationinfo">
              导购上云提升人效 打造全新社交营销场景
            </div>
          </div>
          <div class="operationcard">
            <div class="operationtitle">私域直播</div>
            <div class="operationinfo">直播卖货促进转化 构建私域运营矩阵</div>
          </div>
          <div class="operationcard operationlef">
            <div class="operationtitle">全链路营销</div>
            <div class="operationinfo">
              社交拉新、私域运营，粉丝沉淀、打造品牌闭环
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newRetaillist">
      <div class="list">
        <div class="card">
          <div class="cardimg">
            <img src="../../assets/product/newRetail/11.png" alt="" />
          </div>
          <div class="cardtitle">智慧美业新零售</div>
          <div class="cardinfo">
            从美业发展趋势→门店推广→连锁经营→融资方案→智慧落地，提供全方位解决方案，提高企业运营效率。
          </div>
        </div>

        <div class="card">
          <div class="cardimg">
            <img src="../../assets/product/newRetail/12.png" alt="" />
          </div>
          <div class="cardtitle">母婴市场新零售</div>
          <div class="cardinfo">
            将母婴品牌的线上线下数据全面打通，将母婴门店导购作为突破点，找到离消费者最近的场景——母婴门店。
          </div>
        </div>

        <div class="card">
          <div class="cardimg">
            <img src="../../assets/product/newRetail/13.png" alt="" />
          </div>
          <div class="cardtitle">酒水行业新零售</div>
          <div class="cardinfo">
            通过线上线下一体化管理，把厂家、代理、零售和消费者进行利益绑定，结合LBS定位及智能分仓，实现高效转化。
          </div>
        </div>

        <div class="card">
          <div class="cardimg">
            <img src="../../assets/product/newRetail/14.png" alt="" />
          </div>
          <div class="cardtitle">快销品新零售</div>
          <div class="cardinfo">
            深度结合线上线下优势，打造个性化营销场景，贴近用户生活，有效刺激用户参与消费，推广，提升经营效率。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    var x = 12312;
    if (x < 0) {
      return false;
    }
    let num = x;
    let res = 0;
    while (num != 0) {
      let tmp = num % 10;
      res = res * 10 + tmp;
      console.log(num / 10);
      num = ~~(num / 10);
    }

    if (res === x) {
      return true;
    } else {
      return false;
    }
  },
  methods: {},
};
</script>

<style scoped lang="less">
.newRetaillist {
  margin-top: 90px;
  background-color: #f5f7fa;
  padding-top: 90px;
  padding-bottom: 146px;

  .list {
    display: flex;
    width: 74%;
    margin: auto;
    justify-content: space-between;

    .card {
      width: 310px;
      height: 384px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      padding: 60px 19px;

      .cardimg {
        text-align: center;

        img {
          width: 100px;
        }
      }

      .cardtitle {
        margin-top: 50px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }

      .cardinfo {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-top: 30px;
      }
    }
  }
}

.operation {
  margin-top: 140px;

  .operationtop {
    margin-top: 92px !important;
  }

  .operationlef {
    margin-left: 50px;
  }

  .operationlist {
    margin: auto;
    margin-top: 60px;
    width: 73%;
    display: flex;

    .operationimg {
      img {
        width: 750px;
      }
    }

    .operationcardlist {
      margin-left: 181px;

      .operationcard {
        width: 420px;
        height: 138px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px;
        margin-top: 50px;

        .operationtitle {
          font-size: 24px;
          font-weight: 500;
          color: #2f7ffc;
          line-height: 36px;
        }

        .operationinfo {
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 21px;
          margin-top: 20px;
        }
      }
    }
  }
}

.coreCompetence {
  background-color: #f5f7fa;
  padding-top: 90px;
  padding-bottom: 100px;

  .coreCompetenceimg {
    text-align: center;
    margin-top: 60px;

    img {
      width: 73%;
    }
  }
}

.strength {
  margin-top: 140px;
  padding-bottom: 140px;

  .strengthtop {
    margin-top: 100px;
  }

  .strengthlef {
    margin-left: 30px;
    margin-right: 30px;
  }

  .strengthbor {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
  }

  .strengthlist {
    width: 74%;
    margin: auto;
    margin-top: 60px;
    display: flex;

    .strengthtable {
      border-radius: 16px;
      width: 440px;
      text-align: center;
      background-color: #f5f7fa;
      margin-top: 102px;

      .strengthline {
        height: 70px;
        line-height: 70px;
      }
    }

    .strengthcardlist {
      margin-left: 138px;
      text-align: center;
      color: #ffffff;
      display: flex;

      .backone {
        background-image: url(../../assets/product/newRetail/6.png);
      }

      .backtwo {
        background-image: url(../../assets/product/newRetail/7.png);
      }

      .backthree {
        background-image: url(../../assets/product/newRetail/8.png);
      }

      .strengthcard {
        width: 253px;
        height: 426px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .strengthcardtitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          padding-top: 69px;
        }

        .strengthcardline {
          margin: auto;
          margin-top: 31px;
          margin-bottom: 39px;
          background-color: #ffffff;
          height: 1px;
          width: 58px;
        }

        .strengthcardinfo {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.malpractice {
  margin-top: 140px;

  .malpracticelist {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .malpracticeimg {
      width: 30px;
      height: 62px;
      margin: auto 38px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .malpracticecard {
      width: 397px;
      height: 357px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);

      .malpracticeheard {
        width: 397px;
        height: 173px;
        background: #448bf7;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
        text-align: center;
        box-sizing: border-box;
        padding-top: 47px;

        img {
          max-height: 90px;
          min-height: 80px;
        }
      }

      .malpracticeinfo {
        text-align: center;

        .malpracticetitle {
          margin-top: 40px;
          margin-bottom: 40px;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }

        .malpracticetext {
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

.title2 {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616b80;
  line-height: 33px;
  margin-top: 20px;
}
</style>
